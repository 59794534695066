<template>
    <div class="d-flex justify-content-between border-bottom bg-white">
        <span style="width:40%;" class="ps-2 border-end text-muted" >
            {{ item.Name }}
        </span>
        <span style="width:60%">
            <input class="border-0 w-100" v-if="item.IsProperty" v-model="inputValue"/>
            <input class="border-0 w-100" v-else>
        </span>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { OEditorItem } from 'o365-inputeditors';

const props = defineProps({
    item: Object,
    propertyDataObject: Object
});

const inputValue = computed({
    get () {
        return props.propertyDataObject.data.find(x => x.PropertyName === props.item.Name);
    },
    set (newValue) {
        var item = props.propertyDataObject.data.find(x => x.PropertyName === props.item.Name);
        if(item){
            
        }
    }
}) 

</script>